.Container {
  width: 750px;
  margin: auto;
}

.Content {
  background-color: white;
}

.Content .TagList {
  margin: 30px;
}

.Content h1 {
  color: #224563;
  margin: 30px;
  font-size: 38px;
}

.Content h2 {
  color: #224563;
  margin: 30px;
  font-size: 30px;
}

.Content h3 {
  color: #224563;
  margin: 30px;
  font-size: 24px;
}

.Content img {
  width: calc(100% - 60px);
  height: 200px;
  object-fit: cover;
  margin: 30px;
}

.Content p {
  margin: 30px;
  font-size: 21px;
}

.Content a {
  font-size: 21px;
}

/*            */
/* Responsive */
/*            */

/* MacBook 13" */
@media (max-width: 2600px) {
}

/* Generic Laptop */
@media (max-width: 1400px) {
}

/* iPad Pro */
@media (max-width: 1000px) , (max-width: 1100px) and (min-height: 1000px) {
}

/* Tablets */
@media (max-width: 1000px) {
  .Container {
    width: 700px;
  }
}

/* Phone */
@media (max-width: 700px) , (max-width: 500px) {
  .Container {
    width: 95%;
  }

  .Content .TagList {
    margin: 5px;
    margin-bottom: 15px;
  }

  .Content h1 {
    margin: 5px;
    font-size: 24px;
    margin-bottom: 15px;
  }

  .Content img {
    width: calc(100% - 30px);
    height: 150px;
    object-fit: cover;
    margin: 5px;
    margin-bottom: 15px;
  }

  .Content p {
    font-size: 18px;
    margin: 5px;
  }

  .Content a {
    font-size: 18px;
    margin: 5px;
  }
}
