.Container {
  margin: 30px;
  display: flex;
  align-items: center;
}

.Container p {
  color: #224563;
}

/*            */
/* Responsive */
/*            */

/* MacBook 13" */
@media (max-width: 2600px) {
}

/* Generic Laptop */
@media (max-width: 1400px) {
}

/* iPad Pro */
@media (max-width: 1000px) , (max-width: 1100px) and (min-height: 1000px) {
}

/* Tablets */
@media (max-width: 1000px) {
}

/* Phone */
@media (max-width: 700px) , (max-width: 500px) {
  .Container {
    margin: 5px;
    display: flex;
    flex-direction: column;
  }
}
